import { render, staticRenderFns } from "./ElencoDocumenti.vue?vue&type=template&id=da77e8ec&"
import script from "./ElencoDocumenti.vue?vue&type=script&lang=js&"
export * from "./ElencoDocumenti.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QItemLabel,QAvatar});qInstall(component, 'directives', {Ripple});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('da77e8ec')) {
      api.createRecord('da77e8ec', component.options)
    } else {
      api.reload('da77e8ec', component.options)
    }
    module.hot.accept("./ElencoDocumenti.vue?vue&type=template&id=da77e8ec&", function () {
      api.rerender('da77e8ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Quotazioni/ElencoDocumenti.vue"
export default component.exports